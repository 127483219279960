<template>
    <div>
        <b-container>
            <b-row>
                <b-col md="7">
                    <h3>Vilka är vi på Rato AB?</h3>
                    <p>
                        Rato har varit verksamma inom fastighetsskötsel sedan 2007 och arbetar med många stora kunder så som: MTR, Metrolit Byggnads, Capman och Catella.<br>
                        <br>
                        Vi är 13 stycken duktiga medarbetare som har erfarenhet av att göra i stort sätt alla olika typer av fastighetsskötsel och bygguppdrag.<br>
                        <br>
                        Du kan antingen kontakta oss via frågeformuläret till höger, maila eller slå oss en signal så löser vi ditt problem.<br>
                        <br>
                        <b-row>
                            <b-col md="6" class="mb-4 mb-md-0">
                                Arbetsledare: Nicklas Bejhem<br>
                                <br>
                                Telefon: <a href="tel:+46734444003">+46 73 444 4003</a><br>
                                Epost: <a href="mailto:nicklas@ratoab.se">nicklas@ratoab.se</a>
                            </b-col>
                            <b-col md="6">
                                Arbetsledare: Hasse Azad<br>
                                <br>
                                Telefon: <a href="tel:+46705891111">+46 70 589 11 11</a><br>
                                Epost: <a href="mailto:hasse@ratoab.se">hasse@ratoab.se</a>
                            </b-col>
                        </b-row>
                    </p>
                </b-col>
                <b-col md="5">
                    <contact-form/>
                </b-col>
            </b-row>
        </b-container>

        <our-location class="mt-5"/>
    </div>
</template>

<script>
    import ContactForm from "../ContactForm";
    import OurLocation from "../OurLocation";
    export default {
        name: "AboutUs",
        components: {OurLocation, ContactForm}
    }
</script>

<style scoped>
</style>
