<template>
  <div>
    <hero image="https://api.ratoab.se/HeroImages?location=3"
          :dark-background="false"
          style="height: 60vh"
    />

    <about-us class="mt-5"/>
  </div>
</template>

<script>
  import Hero from "../components/Hero";
  import AboutUs from "../components/About/AboutUs";
  export default {
    name: "Contact",
    components: {AboutUs, Hero},
    mounted () {
      window.scrollTo(0, 0)
    }
  }
</script>

<style scoped>

</style>
